import { safelyRunOnBrowser } from "instantsearch.js/es/lib/utils/index.js";
export function usePoweredBy() {
  var hostname = safelyRunOnBrowser(function (_ref) {
    var _window$location;
    var window = _ref.window;
    return ((_window$location = window.location) === null || _window$location === void 0 ? void 0 : _window$location.hostname) || '';
  }, {
    fallback: function fallback() {
      return '';
    }
  });
  return {
    url: "https://www.algolia.com/?utm_source=react-instantsearch&utm_medium=website&utm_content=".concat(hostname, "&utm_campaign=poweredby")
  };
}